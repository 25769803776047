@import '../theme';

$sidebar-bg-color: $gray-800;
$submenu-bg-color: $gray-800;
$submenu-bg-color-collapsed: $black;
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;
$sidebar-width: 100%;
$sidebar-color: $white;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.slidebar-content {
  background-color: $sidebar-bg-color;
}

.pro-inner-item:before {
  content: '' !important;
  box-shadow: none !important;
  margin-right: 0 !important;
  //border-bottom: $li;
}

.pro-item-content {
  text-overflow: inherit !important;
  white-space: inherit !important;
}
