.api-error {
  position: absolute;
  width: 100%;
  top: 5px;
  z-index: 1000;
  text-align: center;

  .alert {
    margin: auto;
    display: inline-block;
    min-width: 300px;
    text-align: center;
    opacity: 0.9;
  }
}

.btn-redorndo {
  border-radius: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.loading-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, .3);
}

.loading-icon {
  position: absolute;
  font-size: 25px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);

  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(360deg);
  }
  to {
    transform: scale(1) rotate(0deg);
  }
}

#contentProjeto {
  height: calc(100vh - 40px);
}

.dropZone {
  border: 2px dashed #ff3333;
  //width: 100%;
  margin: auto;
  margin-bottom: 20px;
  max-width: 660px;
  font-size: 16px;
  color: #777777;
  text-align: center;
  p {
    width: 100%;
    margin-top: 15px;
    border: none !important;
  }
}
